import { configureStore } from "@reduxjs/toolkit";
import { userMgmtSlice } from "./Reducers/userMgmtSlice";
import { notificationSlice } from "./Reducers/NotificationSlice";
import { supportSlice } from "./Reducers/supportSlice";

export const store = configureStore({
  reducer: {
    usermgmtData: userMgmtSlice.reducer,
    notifications: notificationSlice.reducer,
    support: supportSlice.reducer,
  },
});
