import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { supportListAction } from "../Redux/Actions/adminAction";
import SearchBarComp from "../CommonComp/SearchBarComp";
import { downloadCSV, formatPhoneNumber } from "../utils/CommonFunction";
import SupportConfirmModal from "../Modal/SupportConfirmModal";
import moment from "moment";
import PaginationComponent from "../CommonComp/Pagination";

export default function Support() {
  const dispatch = useDispatch();
  const { supportList } = useSelector((state) => state.support);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [supportModalState, setSupportModalState] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("all");

  const SupportConfirmModaClose = () => {
    setSupportModalState(false);
    setSelectedType("");
    setSelectedId("");
  };

  const SupportConfirmModaOpen = (value, id) => {
    const val = value;
    if (val && val === "1") {
      setSupportModalState(true);
      setSelectedType("CloseSupport");
      setSelectedId(id);
    } else {
      setSupportModalState(true);
      setSelectedType("OpenSupport");
      setSelectedId(id);
    }
  };

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const handleLimitChange = (limitNumber) => setLimit(limitNumber);

  const handleSearch = (searchValue) => getApiCall({ searchValue });

  const handleStatusFilterChange = (status) => {
    setSelectedStatus(status);
  };

  const getApiCall = (options = {}) => {
    const {
      searchValue = "",
      page = currentPage,
      limitValue = limit,
      status = selectedStatus,
    } = options;
    dispatch(supportListAction({
        search: searchValue,
        pagination: page - 1,
        limit: limitValue,
        status: status === "all" ? "" : status, // Apply status filter to API call
      })
    );
  };

  useEffect(() => {
    getApiCall({ searchValue, currentPage, limit, status: selectedStatus });
  }, [
    searchValue,
    currentPage,
    limit,
    dispatch,
    supportModalState,
    selectedStatus,
  ]);

  const handleDownload = async () => {
    let apiRes = await dispatch(
      supportListAction({
        status: selectedStatus === "all" ? "" : selectedStatus,
      })
    );
    if (apiRes?.payload?.status == 200) {
      if (apiRes?.payload?.data?.length > 0) {
        let csvData = apiRes?.payload?.data.map((res, index) => {
          return {
            srno: index + 1,
            ticketId: res?.ticket_no,
            name: res?.receiver?.name,
            email: res?.receiver?.email,
            id: res?._id,
          };
        });
        downloadCSV("support-chat", csvData);
      } else {
        toast.warn("No Data Available to Download");
      }
    } else {
      toast.error(apiRes?.payload?.message);
    }
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Support</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <SearchBarComp onSearch={handleSearch} />
              <Col className="d-flex justify-content-end align-items-center">
                <div className="cmn-btn">
                  <button
                    type="button"
                    style={{ color: "#fff" }}
                    onClick={handleDownload}
                  >
                    DOWNLOAD
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link
                  to="#"
                  className={selectedStatus === "all" ? "active-tab" : ""}
                  onClick={() => handleStatusFilterChange("all")}
                >
                  ALL
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className={selectedStatus === "0" ? "active-tab" : ""}
                  onClick={() => handleStatusFilterChange("0")}
                >
                  Open
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className={selectedStatus === "1" ? "active-tab" : ""}
                  onClick={() => handleStatusFilterChange("1")}
                >
                  Closed
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing 1 - {limit || 10} of {supportList?.count} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => handleLimitChange(e.target.value)}
                >
                  <option>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                  <option value={25}>25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>Ticket Id</th>
                <th>Date</th>
                <th>Sender Name</th>
                <th>Email</th>
                <th>Description</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(supportList?.data) &&
              supportList?.data?.length > 0 ? (
                supportList?.data?.map((res) => {
                  return (
                    <tr key={res?._id}>
                      <td>
                        <Link to={`/SupportChat/${res?._id}`}>
                          {res?.ticket_no || "N/A"}
                        </Link>
                      </td>
                      <td className="no-break-text">
                        <Link to={`/SupportChat/${res?._id}`}>
                          {res?.createdAt
                            ? moment(res?.createdAt).format("DD/MM/YYYY")
                            : "N/A"}
                        </Link>
                      </td>
                      <td>
                        <Link to={`/SupportChat/${res?._id}`}>
                          {res?.receiver?.name || "N/A"}
                        </Link>
                      </td>
                      <td className="no-break-text">
                        <Link to={`/SupportChat/${res?._id}`}>
                          {res?.receiver?.email || "N/A"}
                        </Link>
                      </td>
                      <td>
                        <Link to={`/SupportChat/${res?._id}`}>
                          {res?.message?.length > 50
                            ? res?.message.slice(0, 50) + "..."
                            : res?.message || "N/A"}
                        </Link>
                      </td>
                      <td>
                        {res?.status === 0 ? (
                          <>
                            <div className="select-box">
                              <Form.Select
                                aria-label="Default select example"
                                value={res?.status}
                                onChange={(e) =>
                                  SupportConfirmModaOpen(
                                    e.target.value,
                                    res?._id
                                  )
                                }
                              >
                                <option
                                  value={"0"}
                                  disabled={res?.status == "0"}
                                >
                                  Open
                                </option>
                                <option
                                  value={"1"}
                                  disabled={res?.status == "1"}
                                >
                                  Close
                                </option>
                              </Form.Select>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="form-control text-danger closed-status">
                              Closed
                            </div>
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <div className="not-found-data">
                  <p className="text-center text-muted">No Data Available</p>
                </div>
              )}
            </tbody>
          </Table>
        </div>

        {/* Pagination Comp */}
        <div className="d-flex justify-content-center pagination-set">
          <PaginationComponent
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalData={supportList?.count || 0}
            limit={limit}
            onPageChange={handlePageChange}
          />
        </div>
      </Container>

      {/* <div className="next-btn-fix">
        <Link to="#">Next</Link>
      </div> */}

      <SupportConfirmModal
        supportModalState={supportModalState}
        SupportConfirmModaClose={SupportConfirmModaClose}
        type={selectedType}
        idval={selectedId}
      />
    </Layout>
  );
}
