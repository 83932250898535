import React, { useState, useEffect } from "react";
import { Form, Col } from "react-bootstrap";
import useDebouncedValue from "../CustomHook/useDebounce";

const SearchBarComp = ({ onSearch, placeholder = "Search.." }) => {
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebouncedValue(searchValue, 1000); 

  const handleSearch = (val) => {
    setSearchValue(val);
  };

  useEffect(() => {
    if (onSearch) {
      onSearch(debouncedSearchValue);
    }
  }, [debouncedSearchValue]);

  return (
    <Col xxl={3} xl={3} lg={4} md={4}>
      <Form.Group>
        <Form.Control
          type="search"
          onChange={(e) => handleSearch(e.target.value)}
          value={searchValue}
          placeholder={placeholder}
        />
      </Form.Group>
    </Col>
  );
};

export default SearchBarComp;
