import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function Orders({ exerciseGoal, nutristionGoal }) {
  const data = {
    labels: [`Exercise Goals ${exerciseGoal}%`, `Nutritional Goals ${nutristionGoal}%`],
    datasets: [
      {
        label: "# of Votes",
        data: [exerciseGoal, nutristionGoal],
        backgroundColor: ["#F0C023", "#CB3534"],

        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <Doughnut className="gender-chart" data={data} />
    </>
  );
}
