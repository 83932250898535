import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { getSupportDetailsAction } from "../Redux/Actions/adminAction";
import moment from "moment/moment";

let BASE_URL = process.env.REACT_APP_FILE_BASE_URL;

export default function SupportChat() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { messageList } = useSelector((state) => state.support);

  const localUserId = sessionStorage.getItem("userId");
  const { setSocket } = useSelector((state) => state.support);
  console.log("messageList", messageList);

  const [state, setState] = useState([]);
  const [sendSocketId, setSendSocketIds] = useState({
    sender_id: "",
    receiver_id: "",
  });

  const messagesEndRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      message: "",
    },
    onSubmit: (values) => {
      sendMessage(values.message);
      formik.resetForm();
    },
  });

  const sendMessage = (message) => {
    if (message.trim() !== "") {
      const newMessage = {
        sender_id: sendSocketId?.sender_id,
        receiver_id: sendSocketId?.receiver_id,
        message: message,
        message_type: 0,
        type: 0,
      };
      setSocket.emit("send_message", newMessage);
    }
  };

  useEffect(() => {
    dispatch(getSupportDetailsAction({ id })).then((res) => {
      let data = res?.payload?.data?.res_data
      if (data?.status === 200) {
        let newValues = {
          sender_id: data?.sender_id,
          receiver_id: data?.receiver_id,
        };
        setSendSocketIds({ ...sendSocketId, ...newValues });
        setState(data?.data);
      }
    });
  }, []);

  useEffect(() => {
    if (setSocket) {
      setSocket.on("get_message", (res) => {
        console.log("get", res);
        setState((prevState) => [...prevState, res]);
      });
      return () => {
        setSocket.off("get_message");
      };
    }
  }, [setSocket]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [state]);

  const routeBack = () => {
    navigate(-1);
  };

  console.log("state", state);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Support Chat</h2>
          </div>
          <div></div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={12}>
              <div className="product-tab-left" onClick={routeBack}>
                {/* <Link to="/support"> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="42"
                  viewBox="0 0 40 42"
                  fill="none"
                >
                  <path
                    d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                    fill="#40413A"
                  />
                </svg>{" "}
                Back to previous page
                {/* </Link> */}
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid>
        <Row className="justify-content-center">
          <Col lg={8}>
            <div className="support-chat-box-main">
              <div className="support-inner">
                {/* <h2>Chat</h2> */}
                <div className="support-msg-box">
                  {Array.isArray(state) &&
                  state?.length > 0 ? (
                    state?.map((res, index) => {
                      const isSender = res?.sender_id === localUserId;
                      const messageBoxStyle = {
                        display: "flex",
                        justifyContent: isSender ? "flex-end" : "flex-start",
                        margin: "10px 0",
                      };

                      return (
                        <div
                          className={`user-box ${
                            isSender && "right-side-chat"
                          }`}
                          key={res?._id || index}
                          style={messageBoxStyle}
                        >
                          <div className="add-some-width">  
                            <div className="user-top-hdng">
                              {/* <img
                                src={
                                  res?.user?.profile_pic
                                    ? `${BASE_URL}${res?.user?.profile_pic}`
                                    : require("../Assets/Images/adminplaceholder.png")
                                }
                              /> */}
                              <h3>
                                {res?.user?.name}
                              </h3>
                            </div>
                            <div className="user-msg-box">
                              <p>{res?.message || "N/A"}</p>
                            </div>
                            <h6>{moment(res?.createAt).format("hh:mm A")}</h6>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p>No Message yet</p>
                  )}
                  <div ref={messagesEndRef} />
                </div>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div class="send-box">
                  {messageList?.chat_status === 1 ? (
                    <>
                      <div className="text-center mb-3">
                        <b>Chat is Closed by Super Admin</b>
                      </div>
                    </>
                  ) : (
                    <>
                      <div class="mb-3 input-group chat-msg-bar">
                        <input
                          placeholder="Type message.."
                          aria-label="send"
                          aria-describedby="basic-addon2"
                          name="message"
                          type="text"
                          className="send-feild form-control"
                          value={formik.values.message}
                          onChange={formik.handleChange}
                        />
                        <button
                          class="input-group-text"
                          id="basic-addon2"
                          type="submit"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="18"
                            viewBox="0 0 16 18"
                            fill="none"
                          >
                            <path
                              d="M0.523716 17.2572C0.110234 16.7692 -0.162488 16.0958 0.108768 15.3141C0.544243 14.0606 1.01344 12.8205 1.47531 11.576C1.69671 10.9782 1.93864 10.3879 2.16005 9.79155C2.20697 9.66398 2.26562 9.62838 2.39611 9.62986C4.24651 9.63431 6.09838 9.63134 7.94879 9.63431C8.17312 9.63431 8.3696 9.58536 8.51476 9.39994C8.67018 9.20266 8.70244 8.98609 8.60273 8.75765C8.50743 8.5396 8.33588 8.41055 8.09834 8.38533C8.02796 8.37791 7.95612 8.38088 7.88574 8.38088C6.06026 8.38088 4.23479 8.3794 2.41077 8.38533C2.26268 8.38533 2.19964 8.33786 2.14832 8.20139C1.4929 6.46736 0.828695 4.73629 0.174749 3.00225C-0.361897 1.57972 0.531047 0.117134 1.99729 0.00588286C2.4489 -0.0282342 2.86531 0.0874672 3.25533 0.315903C7.12476 2.57505 10.9956 4.83271 14.8651 7.09185C16.1803 7.86022 16.3914 9.58388 15.2932 10.6059C15.1671 10.7246 15.0205 10.8254 14.8709 10.913C11.0015 13.1736 7.13209 15.4327 3.2612 17.6904C2.36239 18.211 1.33308 18.0805 0.523716 17.2572Z"
                              fill="white"
                            />
                          </svg>
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
