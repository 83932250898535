import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getSupportDetailsAction, supportListAction } from "../Actions/adminAction";

export const supportSlice = createSlice({
  name: "support",
  initialState: {
    messageList: {},
    setSocket: null,
    setSocketState: null,
    supportList: []
  },
  reducers: {
    setSocket: (state, action) => {
      state.setSocket = action.payload;
      state.setSocketState = action.payload;
      
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSupportDetailsAction?.fulfilled, (state, action) => {
        if (action?.payload?.data?.res_data?.status === 200) {
          state.messageList = action?.payload?.data?.res_data;
        } else {
          toast.error(action?.payload?.message);
        }
      })
      .addCase(supportListAction.fulfilled, (state, action) => {
        state.supportList = action?.payload;
      });
      
  },
});

export const { setSocket, setSocketState } = supportSlice.actions;
export default supportSlice.reducer;
