import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteNotification,
  NotificationListing,
} from "../Redux/Actions/adminAction";
import Search from "../Components/Layout/Search";
import Limit from "../Components/Layout/Limit";
import Pagination from "../Components/Layout/Pagination";
import { toast } from "react-toastify";

export default function Notification() {
  const data = useSelector((state) => state.notifications.alldata);
  console.log(data, "userList");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [page, setpage] = useState("1");
  const [search, setsearch] = useState("");
  const [limit, setlimit] = useState("10");
  const [flag, setflag] = useState(true);
  const [showd, setshowd] = useState(false);
  const [id, setID] = useState("");

  const location = useLocation();
  const pageNo = new URLSearchParams(location?.search).get("page");

  const handlePageChange = (pageNumber) => {
    setpage(pageNumber + 1);
    navigate(`/Notification?page=${pageNumber + 1}`);
  };

  useEffect(() => {
    dispatch(NotificationListing({ pageNo, search, limit, type: "" }));
  }, [pageNo, search, limit, flag]);

  const handleDeleteUSer = () => {
    dispatch(DeleteNotification({ id: id }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.status === 200) {
          toast?.success(res?.payload?.message);
          setshowd(false);
          setflag(!flag);
        }
      })
      .catch((err) => {
        console.log(err, "err Occur");
      });
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Notifications</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Search setsearch={setsearch} />
                </Form.Group>
              </Col>

              <Col
                xxl={9}
                xl={9}
                lg={8}
                md={8}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link to="/AddNotification">Create New</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/Notification" className="active-tab">
                  ALL
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                Showing {1 + Number(pageNo - 1) * limit} -{" "}
                {data?.data?.length + Number(page - 1) * limit} of {data?.count}{" "}
                results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span> <Limit setlimit={setlimit} />
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Title</th>
                <th>Description</th>
                <th>Sent to</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data?.data?.map((res, index) => {
                const serialNumber = (pageNo - 1) * limit + index + 1;
                return (
                  <tr key={index}>
                    <td>{serialNumber}</td>
                    <td>{res?.title || "N/A"}</td>
                    <td>
                      {res?.description?.length > 100
                        ? res?.description.slice(0, 100) + "..."
                        : res?.description || "N/A"}
                    </td>
                    {/* <td>
                      {res?.sent_to === 1
                        ? "All Users"
                        : res?.sent_to === 2
                        ? res?.user_ids.reduce((acc, user, index) => {
                            return acc + (index > 0 ? ", " : "") + user?.name;
                          }, "")
                        : "N/A"}
                    </td> */}
                    <td>
                      {res?.sent_to === 1
                        ? "All Users"
                        : res?.sent_to === 2
                        ? res?.user_ids.map((user) => user?.name).join(", ")
                        : "N/A"}
                    </td>
                    <td>
                      <Button
                        className="account-btn common-colr-btn"
                        onClick={() => {
                          setshowd(true);
                          setID(res?._id);
                        }}
                      >
                        DELETE
                      </Button>{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>

      <Pagination
        totalstuff={Math.ceil(data?.count / limit)}
        limit={limit}
        setpages={handlePageChange}
        search={search}
        pagenumber={Number(pageNo) - 1}
      />

      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h4 className="are-sure-text">
            Are you sure you want to Delete this Notification?
          </h4>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleDeleteUSer}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowd(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
