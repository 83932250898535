import React from "react";
import { Button, Modal, } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateSupportAction } from "../Redux/Actions/adminAction";

const SupportConfirmModal = ({ supportModalState, SupportConfirmModaClose, type, idval }) => {
    const dispatch = useDispatch()
    const handleSupportStatusChange = async (status) => {
        try {
            const response = await dispatch(updateSupportAction({ id: idval, status: status }));
            if (response?.payload?.res_data?.status == 200) {
                toast.success(response?.payload?.res_data?.message);
                SupportConfirmModaClose()
            } else {
                toast.error(response?.payload?.res_data?.message);
            }

        } catch (error) {
            console.error("Error fetching contact details:", error);
        }
    }
    return (
        <Modal
            show={supportModalState}
            onHide={SupportConfirmModaClose}
            className="comn-modal-set"
        >
            <Modal.Header className="border-none" closeButton>
                {type === "CloseSupport" && <Modal.Title className="text-center modal-title ">
                    Close Support Chat
                </Modal.Title>}
                {type === "OpenSupport" && <Modal.Title className="text-center modal-title ">
                    Open Support Chat
                </Modal.Title>}

            </Modal.Header>

            <Modal.Body>

                {type === "CloseSupport" && <p className="text-center suspend-text">
                    {" "}
                    Are you sure you want to close this support chat ?
                </p>}
                {type === "OpenSupport" && <p className="text-center suspend-text">
                    {" "}
                    Are you sure you want to open this support chat ?
                </p>}

            </Modal.Body>
            <Modal.Footer className="border-none justify-content-center modal-footer">

                {type === "CloseSupport" && <Button
                    onClick={() => handleSupportStatusChange(1)}

                    variant="danger"
                    className="modal-close-btn "
                >
                    Yes
                </Button>}
                {type === "OpenSupport" && <Button
                    onClick={() => handleSupportStatusChange(0)}

                    variant="danger"
                    className="modal-close-btn "
                >
                    Yes
                </Button>}

                <Button
                    variant="secondary"
                    onClick={SupportConfirmModaClose}
                    className="modal-f-btn "
                >
                    No
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default SupportConfirmModal
