import React, { useEffect, useState } from "react";
import Login from "./pages/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginOtp from "./pages/LoginOtp";
import ForgotPassword from "./pages/ForgotPassword";
import UserManagement from "./pages/UserManagement";
import Support from "./pages/Support";
import Dashboard from "./pages/Dashboard";
import Supportchat from "./pages/SupportChat";
// import UserActive from "./pages/UserActive";
import CustomerProfile from "./pages/CustomerProfile";
import Notification from "./pages/Notification";
import AddNotification from "./pages/AddNotification";
import ChangePassword from "./pages/ChangePassword";
import ManagerProfile from "./pages/ManagerProfile";
import ForgotPasswordtwo from "./pages/ForgotPasswordtwo";
import UserActive from "./pages/UserActive";

import Usersuspend from "./pages/UserSuspend";
import ProtectedRoutes from "./Components/Layout/ProtectedRoutes";
import TermsofUse from "./pages/TermsofUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { useDispatch } from "react-redux";
import { io } from "socket.io-client";
import { setSocket } from "./Redux/Reducers/supportSlice";
function App() {
  const [socket, setsocket] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    let socketConnection;
    const token = sessionStorage.getItem("token");
    socketConnection = io("https://she-tracks-api.bosselt.com", {
      extraHeaders: {
        token: token,
      },
    });
    setsocket(socketConnection);
  }, []);

  console.log(socket, "socket");

  useEffect(() => {
    if (socket) {
      dispatch(setSocket(socket));
    }
  }, [socket]);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        
          <Route path="/" element={<Login />} />
          <Route path="/Otp" element={<LoginOtp />} />
          <Route path="/Forgot-Password" element={<ForgotPassword />} />
          <Route path="/terms-of-use" element={<TermsofUse />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/ChangePasswordtwo" element={<ForgotPasswordtwo />} />


           <Route element={<ProtectedRoutes/>}>
          <Route path="/UserManagement" element={<UserManagement />} />
          <Route path="/Support" element={<Support />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/CustomerProfile/:id" element={<CustomerProfile />} />
          <Route path="/Supportchat/:id" element={<Supportchat />} />
          {/* <Route path="/UserActive" element={<UserActive />} /> */}
          <Route path="/Notification" element={<Notification />} />
          <Route path="/AddNotification" element={<AddNotification />} />
          <Route path="/ChangePassword" element={<ChangePassword />} />
          <Route path="/ManagerProfile" element={<ManagerProfile />} />
          
          <Route path="/ActiveUser" element={<UserActive />} />
          <Route path="/SuspendUser" element={<Usersuspend />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
