import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Layout from "../Components/Layout/Layout";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { SendNotification, userListing } from "../Redux/Actions/adminAction";
import { toast } from "react-toastify";
import Select from "react-select";

const validationSchema = Yup.object().shape({
  sent_to: Yup.string().required("Required"),
  title: Yup.string().trim().required("Required"),
  description: Yup.string().trim().required("Required"),
});

export default function AddNotification() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getAllUsersList = useSelector(
    (state) => state.usermgmtData.userList.data
  );

  const initialvalues = {
    sent_to: "",
    title: "",
    user_ids: [],
    description: "",
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#fbd9d3" : "#fff",
      color: "#333",
      cursor: "pointer",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  const userOptions = getAllUsersList?.map((res) => ({
    value: res._id,
    label: res?.name || "N/A",
  }));

  console.log("getAllUsersList", getAllUsersList);

  useEffect(() => {
    dispatch(userListing({ search: "" }));
  }, [dispatch]);

  const handleSubmit = async (values) => {
    if (Number(values?.sent_to) === 2) {
      if (values?.user_ids?.length == 0) {
        return toast.warn("At least one user must be selected");
      }
    }
    let payload = {
      title: values?.title,
      sent_to: Number(values?.sent_to),
      description: values?.description,
    };
    if (values?.user_ids?.length) {
      payload = { ...payload, user_ids: values?.user_ids || [] };
    }
    console.log("payload", payload);
    const data = await dispatch(SendNotification(payload));
    console.log("send data", data);
    if (data?.payload?.status === 200) {
      toast.success(data?.payload?.message);
      navigate("/Notification?page=1");
    } else {
      toast.error(data?.payload?.data?.message);
    }
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <h2>Create Notification</h2>
          <div>
            <hr />
          </div>
        </div>
      </div>
      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="#" onClick={() => navigate(-1)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid>
        <Row className="justify-content-center ">
          <Formik
            initialValues={initialvalues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, handleSubmit, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <Col lg={8}>
                  <div className="customer-form-new">
                    <h2>BASIC DETAILS</h2>
                    <hr className="cmn-border" />
                    <div className="customer-form-inner">
                      <Row>
                        <Col lg={12}>
                          <div className="admin-box-select">
                            <Form.Group className="mb-3">
                              <Form.Label>Select Users</Form.Label>

                              <Form.Select
                                aria-label="Default select example"
                                name="sent_to"
                                // value={values?.sent_to}
                                // onChange={handleChange}
                                onChange={(e) => {
                                  setFieldValue("sent_to", e.target.value);
                                  if (e.target.value !== "2") {
                                    setFieldValue("user_ids", []);
                                  }
                                }}
                              >
                                <option>Select</option>
                                <option value="1">All Users</option>
                                <option value="2">Selected Users</option>
                              </Form.Select>
                              <ErrorMessage
                                name="sent_to"
                                component="div"
                                className="error-message"
                              />
                            </Form.Group>
                          </div>
                        </Col>

                        {values?.sent_to === "2" && (
                          <Col lg={12}>
                            <div className="height-none drop-icon-set-long">
                              <label>Selected Users</label>
                              <Select
                                styles={customStyles}
                                name="user_ids"
                                options={userOptions}
                                placeholder="Select Users"
                                isMulti
                                onChange={(option) => {
                                  const ids =
                                    option?.map((opt) => opt.value) || [];
                                  setFieldValue("user_ids", ids);
                                }}
                                value={values?.user_ids?.map((id) => ({
                                  value: id,
                                  label: getAllUsersList.find(
                                    (user) => user._id === id
                                  )?.name,
                                }))}
                              />
                              <ErrorMessage
                                name="user_ids"
                                className="text-danger text-danger-set"
                                component="div"
                              />
                            </div>
                          </Col>
                        )}

                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Add Title..........."
                              name="title"
                              value={values?.title}
                              onChange={handleChange}
                            />
                            <ErrorMessage
                              name="title"
                              component="div"
                              className="error-message"
                            />
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={5}
                              placeholder="Add Description.........."
                              name="description"
                              value={values?.description}
                              onChange={handleChange}
                            />
                            <ErrorMessage
                              name="description"
                              component="div"
                              className="error-message"
                            />
                          </Form.Group>
                        </Col>

                        <Col
                          lg={12}
                          className="d-flex justify-content-center mt-3"
                        >
                          <button type="submit" className="add-btn">
                            create
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Form>
            )}
          </Formik>
        </Row>
      </Container>
    </Layout>
  );
}
