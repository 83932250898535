import moment from "moment";
import { useLocation } from "react-router-dom";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const serialNumber = (currentPage, limit, index) => {
    return (currentPage - 1) * limit + index + 1;
}

const constructQueryString = (obj) => {
    if (obj) {
        const queryString = Object.keys(obj)
            .filter(key => obj[key] !== "" && obj[key] !== null && obj[key] !== undefined)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
            .join('&');
        return queryString;
    } else {
        return ""
    }
}

const capitalizeFirstLetter = (string) => {
    if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
        return `N/A`
    }
}

const fullName = (firstName, lastName) => {
    let name = firstName ? `${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(lastName)}` : 'N/A'
    return name;
}

const formatPhoneNumber = (countryCode, phoneNumber) => {
    if (countryCode) {
        if (!countryCode?.startsWith('+')) {
            countryCode = '+' + countryCode;
        }
    }
    return `${phoneNumber ? `${countryCode || ""} ${phoneNumber}` : 'N/A'}`;
}

const getGender = (gender) => {
    if (gender == 1) {
        return 'Male'
    } else if (gender == 2) {
        return 'Female'
    } else if (gender == 3) {
        return 'Other'
    } else {
        return 'N/A'
    }
}

const convertTimeToUTC = (time) => {
    let utcTime = moment(time, "HH:mm").utc().format("HH:mm");
    return utcTime;
};

const convertUTCToLocal = (utcTime) => {
    let localTime = moment.utc(utcTime, "HH:mm").local().format("HH:mm");
    return localTime;
};

const downloadFile = (file_path) => {
    var a = document.createElement('a');
    a.href = file_path;
    a.target = "_blank";
    a.download = file_path.substring(file_path.lastIndexOf('/') + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

const createCsv = async (data) => {
    let keys = Object?.keys(data[0]);
    let result = '';
    result += keys.join(',');
    result += '\n';
    data?.forEach((element) => {
        keys.forEach((key) => {
            result += `${element[key] ? element[key] : 'N/A'}` + ',';
        })
        result += '\n';
    });
    return result;
}
const downloadCSV = async (name, array) => {
    console.log("array", array)
    if (array?.length > 0) {
        let csv = 'data:text/csv;charset=utf-8,' + await createCsv(array);
        let excel = encodeURI(csv);
        let link = document.createElement('a');
        link.setAttribute('href', excel);
        link.setAttribute('download', `${name}.csv`);
        link.click();
    }
    return
}

const downloadURL = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.download = '';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export {
    useQuery,
    constructQueryString,
    fullName,
    capitalizeFirstLetter,
    formatPhoneNumber,
    getGender,
    serialNumber,
    convertTimeToUTC,
    convertUTCToLocal,
    downloadCSV,
    downloadFile,
    downloadURL,
}